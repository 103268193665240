import ApiService from "../../api.service"
import { MUTATIONS } from "./mutations"

export const API_ACTIONS = {
    GET_CAUSE_OF_FAILURES: "(api action) get cause of failures list",
    GET_CUSTOMER_LOCATION_GROUPS: "(api action) get customer location groups list by selected accounts",
    GET_RESTRICTED_CUSTOMER_LOCATION_GROUPS: "(api action) get restricted customer location groups list by web id",
    GET_CUSTOMER_LOCATION_REGIONS: "(api action) get customer location region list by selected region",
    GET_COUNTRIES: "(api action) get countries",
}

export const actions = {
    [API_ACTIONS.GET_CAUSE_OF_FAILURES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("selects/get_cause_of_failures", context)
            .then(({ data }) => {
                context.commit(MUTATIONS.GET_CAUSE_OF_FAILURES, data);
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
        })
    },
    [API_ACTIONS.GET_CUSTOMER_LOCATION_GROUPS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("selects/get_customer_location_groups", params, context)
            .then(({ data }) => {
                context.commit(MUTATIONS.GET_CUSTOMER_LOCATION_GROUPS, data);
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
        })
    },
    [API_ACTIONS.GET_RESTRICTED_CUSTOMER_LOCATION_GROUPS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("selects/get_restricted_customer_location_groups", params, context)
            .then(({ data }) => {
                context.commit(MUTATIONS.GET_RESTRICTED_CUSTOMER_LOCATION_GROUPS, data);
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
        })
    },
    [API_ACTIONS.GET_CUSTOMER_LOCATION_REGIONS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("selects/get_customer_location_regions", params, context)
            .then(({ data }) => {
                context.commit(MUTATIONS.GET_CUSTOMER_LOCATION_REGIONS, data);
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
        })
    },
    [API_ACTIONS.GET_COUNTRIES](context, regionName) {
        return new Promise((resolve, reject) => {
            ApiService.get("selects/get_countries/" + regionName, context)
                .then(({ data }) => {
                    context.commit(MUTATIONS.GET_COUNTRIES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
        })
    },
}