import { actions } from "./actions";
import { mutations } from "./mutations";

export const GETTERS = {
    CAUSE_OF_FAILURES: "(getter) get cause of failures list",
    CUSTOMER_LOCATION_GROUPS: "(getter) get customer location groups list",
    CUSTOMER_LOCATION_REGIONS: "(getter) get customer location regions list",
    COUNTRIES: "(getter) get countries",
}

const state = {
    causeOfFailures: [],
    locationGroups: null,
    locationRegions: null,
    countries: [],
}

const getters = {
    [GETTERS.CAUSE_OF_FAILURES]: () => state.causeOfFailures,
    [GETTERS.CUSTOMER_LOCATION_GROUPS]: () => state.locationGroups,
    [GETTERS.CUSTOMER_LOCATION_REGIONS]: () => state.locationRegions,
    [GETTERS.COUNTRIES]: () => statte.countries,
}

export default {
    state,
    getters,
    actions,
    mutations
}