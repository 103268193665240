export const MUTATIONS = {
    GET_CAUSE_OF_FAILURES: "(mutation) get cause of failures list",
    GET_CUSTOMER_LOCATION_GROUPS: "(mutation) get customer location groups list",
    GET_RESTRICTED_CUSTOMER_LOCATION_GROUPS: "(mutation) get restricted customer location groups list",
    GET_CUSTOMER_LOCATION_REGIONS: "(mutation) get customer location regions list",
    GET_COUNTRIES: "(mutation) get countries",
}

export const mutations = {
    [MUTATIONS.GET_CAUSE_OF_FAILURES](state, data) {
        state.causeOfFailures = data;
    },
    [MUTATIONS.GET_CUSTOMER_LOCATION_GROUPS](state, data) {
        state.locationGroups = data || [];
    },
    [MUTATIONS.GET_RESTRICTED_CUSTOMER_LOCATION_GROUPS](state, data) {
        state.locationGroups = data || [];
    },
    [MUTATIONS.GET_CUSTOMER_LOCATION_REGIONS](state, data) {
        state.locationRegions = data || [];
    },
    [MUTATIONS.GET_COUNTRIES](state, data) {
        state.countries = data || [];
    },
}