import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { APP_RESET_INTERFACE, APP_SET_AUTHENTICATED } from "@/core/services/app.state.module";
import router from '@/router';

// action types
export const API_LOGIN = "login";
export const API_REDIRECT = "redirect";
export const API_LOGOUT_RESET = "logout_reset";
export const API_LOGOUT = "logout";
export const API_VERIFY_AUTH = "verify";
export const API_START_APP = "start_app";
export const API_GYAUTH = "login_by_gy_token";

export const API_LANGUAGE_CHANGED = "language_changed";

export const API_CHECK_PERMISSIONS = "check_permissions";

// mutation types
export const API_DISPLAY_SSO = "displaysso";
export const API_USER_REDIRECT = "authRedirectByUserGroup";
export const SET_AUTH = "setUser";
export const API_PURGE_AUTH = "logOut";

const state = {
    lastError: "",
    displaysso: 0,
};

const getters = {
    getDisplaySso() {
        return state.displaysso;
    },
    getAuthLastError() {
        return state.lastError;
    },
    getRouter() {
        return router;
    }
};

const actions = {
    [API_LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("login", credentials, context)
                .then(({ data }) => {
                    context.commit(APP_SET_AUTHENTICATED, true);
                    context.commit(SET_AUTH, data);
                    if(this.getters.AppGetUser.AgreeToFhqTerms) {
                        context.commit(API_USER_REDIRECT, context);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.dispatch(API_LOGOUT_RESET);
                    if (response && response.data) {
                        context.commit(SET_AUTH, response.data);
                    }
                    reject(response);
                });
        });
    },
    [API_REDIRECT](context, credentials) {
        return new Promise((resolve, reject) => {
            var returnUrl = ""
            var returnHost = ""
            const urlParams = new URLSearchParams(window.location.search)
            var returnUrl = urlParams.get("returnUrl") || "";

            var getReturnHost = urlParams.get("returnHost")
            if(getReturnHost)
            {
                returnHost =  new URL(getReturnHost)?.origin || "";
            }
            
            const urlQuery = "?returnHost=" + returnHost + "&returnUrl=" + returnUrl;
            ApiService.get("redirect/" + urlQuery, context)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    if (response && response.data) {
                        context.commit(SET_AUTH, response.data);
                    }
                    reject(response);
                });
        });
    },
    [API_GYAUTH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("login_by_gy", credentials, context)
                .then(({ data }) => {
                    context.commit(APP_SET_AUTHENTICATED, true);
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.dispatch(API_LOGOUT_RESET);
                    if (response && response.data) {
                        context.commit(SET_AUTH, response.data);
                    }
                    reject(response);
                });
        });
    },
    [API_START_APP](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("start", context)
                .then(({ data }) => {
                    context.commit(API_DISPLAY_SSO, data?.displaysso);
                    resolve(data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    [API_LOGOUT_RESET](context) {
        context.commit(API_PURGE_AUTH);
        context.commit(APP_SET_AUTHENTICATED, false);
        context.dispatch(APP_RESET_INTERFACE);
    },
    [API_LOGOUT](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("logout", context)
            .then(({ data }) => {
                context.commit(API_PURGE_AUTH);
                context.commit(APP_SET_AUTHENTICATED, false);
                context.dispatch(APP_RESET_INTERFACE);
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            });
        });
    },
    [API_USER_REDIRECT](context) {
        context.commit(API_USER_REDIRECT, context);
    },
    [API_VERIFY_AUTH](context) {
        return new Promise((resolve, reject) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("verify", context)
                    .then(({ data }) => {
                        context.commit(APP_SET_AUTHENTICATED, true);
                        context.commit(SET_AUTH, data);
                        resolve(this);
                    })
                    .catch(() => {
                        context.dispatch(API_LOGOUT_RESET);
                        reject();
                    });
            } else {
                context.dispatch(API_LOGOUT_RESET);
                reject();
            }
        });
    },
    [API_LANGUAGE_CHANGED](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("language", credentials, context)
                .then(() => {
                    resolve();
                })
                .catch((response) => {
                    let status = response.status;
                    if(status == 403) {
                        context.dispatch(API_LOGOUT_RESET);
                    }
                    reject(response);
                }); 
        })
    },
    [API_CHECK_PERMISSIONS](context, params) {
        console.log('API_CHECK_PERMISSIONS');
        if (ApiService.isCheckPermissionSent == true) {
            return new Promise((resolve, reject) => { resolve(); });
        }
        ApiService.isCheckPermissionSent = true;
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("check_permissions", params, context)
                .then(({ data }) => {
                    ApiService.isCheckPermissionSent = false;
                    resolve(data.isAccess);
                })
                .catch(() => {
                    ApiService.isCheckPermissionSent = false;
                    reject();
                });
        });    
    }
};

const mutations = { 
    [API_USER_REDIRECT](state, context) {
        if (this.getters.AppIsAuthenticated) {
            let url = window.app.$route.query;
            if (url.returnUrl) {
                const sliceParam = (paramName) => {
                    var index = url.returnUrl.indexOf(paramName + '=');
                    // console.log(url.returnUrl);
                    while (index != -1) {
                        // console.log(index);
                        var nextIndex = url.returnUrl.indexOf('&', index + 1);
                        // console.log(nextIndex);
                        if (nextIndex != -1) {
                            // console.log(url.returnUrl.slice(index, nextIndex));
                            url.returnUrl = url.returnUrl.replace(url.returnUrl.slice(index, nextIndex), '');
                        }
                        else {
                            // console.log(url.returnUrl.slice(index, url.returnUrl.length));
                            url.returnUrl = url.returnUrl.replace(url.returnUrl.slice(index, ((url.returnUrl.indexOf('&', index + 1) == -1) ? url.returnUrl.length : url.returnUrl.indexOf('&', index + 1))), '');
                        }
                        index = url.returnUrl.indexOf('&' + paramName + '=');
                        // console.log(url.returnUrl);
                    }
                };
                sliceParam('lang');
                sliceParam('user');
            }          
            

            // window.app.$root.$emit('show-notify', 'Help us to translate our service to local language');

            if (Object.prototype.hasOwnProperty.call(url, 'returnUrl') || Object.prototype.hasOwnProperty.call(url, 'returnHost')) {
                let return_url;
                let localRedirect = true;
                //remote host
                if (Object.prototype.hasOwnProperty.call(url, 'returnUrl') && Object.prototype.hasOwnProperty.call(url, 'returnHost')) {
                    if (url.returnHost.indexOf('http://') != 0 && url.returnHost.indexOf('https://') != 0) {
                        url.returnHost = "http://" + url.returnHost;
                    }
                    return_url = url.returnHost;
                    return_url += url.returnUrl;
                    localRedirect = false;
                }
                //remote host without path
                if (!Object.prototype.hasOwnProperty.call(url, 'returnUrl') && Object.prototype.hasOwnProperty.call(url, 'returnHost')) {
                    if (url.returnHost.indexOf('http://') != 0 && url.returnHost.indexOf('https://') != 0) {
                        url.returnHost = "http://" + url.returnHost;
                    }
                    return_url = url.returnHost;                    
                    localRedirect = false;
                }

                var _user = this.getters.AppGetUser;
                //local
                if (Object.prototype.hasOwnProperty.call(url, 'returnUrl') && !Object.prototype.hasOwnProperty.call(url, 'returnHost')) {
                    
                    if(_user.Group == 'BST') {
                        window.app.$root.$emit('show-popup', {
                            title: 'Information',
                            message: 'Access restricted.',
                            isError: true,
                            isClosable: false,
                        })
                    }
                    else {
                        router.push({ path: decodeURI(url.returnUrl) });
                    }
                    return;
                }
                //let return_url_params = new URLSearchParams(return_url);
                let has_params = return_url.indexOf('?') != -1;
                for (let index_param in url) {
                    if (index_param == 'returnUrl') continue;
                    if (index_param == 'returnHost') continue;
                    if (!has_params) return_url += "?"; else return_url += "&";
                    return_url += index_param + "=" + url[index_param];
                    has_params = true;
                    //return_url_params.set(index_param, url[index_param]);
                }
                //return_url.search = return_url_params.toString();

                if (!localRedirect) {
                    if (has_params) {
                        return_url += "&user=" +  _user.GyToken;
                    } else {
                        return_url += "?user=" +  _user.GyToken; 
                    }
                    //return_url.set('user', _user.GyToken);
                }                
                //console.log(return_url);
                this.CloseRedirectPopup = function(){
                    window.app.$root.$emit('close-loader');                
                }
                window.app.$root.$emit('show-loader', 'Redirecting...');
                setTimeout(this.CloseRedirectPopup, 5000);    
                
                location = (return_url);

            } 
            else {
                var _user = this.getters.AppGetUser;
            
                if (_user.Group == "GYR") {
                    window.app.$router.push({ name: "business-solutions-dashboard" });
                    //console.log('_user.Group == "GYR"');
                    //console.log(_user);
                    //window.app.$root.$emit('show-notify', 'Help us to translate our service to local language');
                }
                if (_user.Group == "SSC") {
                    window.app.$router.push({ name: "business-solutions-dashboard" });
                }
                if (_user.Group == "DLR") {
                    window.app.$router.push({ name: "dealer-portal-dashboard" });
                }
                if (_user.Group == "WIN") {
                    window.app.$router.push({ name: "dealer-portal-dashboard" });
                }
                if (_user.Group == "CUS") {
                    window.app.$router.push({ name: "fleet-central-dashboard" });
                }
                if (_user.Group == "BST") {
                    window.app.$root.$emit('show-popup', {
                        title: 'Information',
                        message: 'Access restricted.',
                        isError: true,
                        isClosable: false,
                    })
                }
            }        
        }
    },   
    [SET_AUTH](state, userObject) {
        if (Object.prototype.hasOwnProperty.call(userObject, 'validateStatusResponce')) {
            if (Object.prototype.hasOwnProperty.call(userObject.validateStatusResponce, 'status')) {
                if (userObject.validateStatusResponce.status == "Success") { //success
                    this.commit(APP_SET_AUTHENTICATED, true);
                }
            }
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'authorizationStatus')) {
            
            if (Object.prototype.hasOwnProperty.call(userObject.authorizationStatus, 'status')) {
                if (userObject.authorizationStatus.status == 0) { //success
                    JwtService.saveToken(userObject.user.token);
                    state.lastError = "";
                }
                else if (userObject.authorizationStatus.status == 1) { //locked
                    JwtService.destroyToken();
                    JwtService.destroyGmcToken();
                    JwtService.destroyGmcAuthToken();
                    state.lastError = "Account is locked due to too many failed request attempts";
                }
                else if (userObject.authorizationStatus.status == 3) { //Failure  common exception, used only by B2C Login
                    JwtService.destroyToken();
                    JwtService.destroyGmcToken();
                    JwtService.destroyGmcAuthToken();
                    state.lastError = "An error occurred while trying to login, please try again. If issues persist, contact Solutions Support for further assistance at (866) 503-0510 or email solutions@goodyear.com.";
                }
                else if (userObject.authorizationStatus.status == 4) { // SignInStatus 4 - inccorect username or password
                    JwtService.destroyToken();
                    JwtService.destroyGmcToken();
                    JwtService.destroyGmcAuthToken();
                    state.lastError = "Invalid Username or Password";
                }
                else if (userObject.authorizationStatus.status == 5) { //SignInStatus 5 - is Local user exception
                    JwtService.destroyToken();
                    JwtService.destroyGmcToken();
                    JwtService.destroyGmcAuthToken();
                    state.lastError = "The provided credentials did not match any existing users with local authorization. Try signing in with one of the identity providers.";
                }
            }
        }
    },
    [API_PURGE_AUTH]() {
        JwtService.destroyToken();
        JwtService.destroyGmcToken();
        JwtService.destroyGmcAuthToken();
    },
    [API_DISPLAY_SSO](state, displaysso) {
        state.displaysso = displaysso
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};